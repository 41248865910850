<template>
  <div class="min-h-screen bg-gradient-to-b from-bgFrom to-bgEnd flex flex-col">
    <div class="bg-bgColor" style="min-height: 109px">
      <div class="pt-3 pb-3 flex flex-row items-center mx-auto" style="max-width: 500px">
        <img @click="toHome" src="../assets/images/logo.png" width="63px" height="auto" alt="" class="mx-4">
        <div class="flex-1">
          <h1 class="font-black text-white text-left">First DAO</h1>
          <div class="flex flex-row items-center">
            <div class="text-white text-sm font-medium">{{fixWallet}}</div>
            <div>
              <img src="../assets/images/copy.png" alt="" style="width: auto; height: 9px" class="mx-2">
            </div>
            <img v-if="nodeInfo[0]||nodeInfo[1]" src="../assets/images/node.png" alt="" style="height: 13px; width: auto">
            <img v-if="(!nodeInfo[0]&&!nodeInfo[1])" src="../assets/images/node-n.png" alt="" style="height: 13px; width: auto">

            <img v-if="nodeInfo[1]" src="../assets/images/super-node.png" alt="" style="height: 13px; width: auto" :class="'ml-1'">
            <img v-if="!nodeInfo[1]" src="../assets/images/super-node-n.png" alt="" style="height: 13px; width: auto" :class="'ml-1'">
          </div>
          <div class="text-white text-sm font-medium text-left">
            {{$t("invited")}} {{fixTimes(userInfo.joinTime)}}
<!--            by <span class="ml-1 text-idColor text-sm font-medium">ID 12</span>-->
          </div>
        </div>
        <div @click="handleMenu" class="mx-4">
          <img src="../assets/images/menu.png" style="width: 24px; height: auto" alt="">
        </div>
      </div>
    </div>
    <div class="flex-1" :class="bgColor">
      <div class="mx-auto" style="max-width: 500px">
        <router-view></router-view>
      </div>
    </div>
    <el-drawer
        title=""
        :visible.sync="drawer"
        :show-close="false"
        :withHeader="false"
        :size="'216px'"
        direction="rtl">
      <div @click="drawer = false" style="height: 109px; width: 100%;"/>
      <div style="height: 100%; background-color: #17191B">
        <div @click="tableChanged(0)" class="draw-lable flex items-center" :class="isGNode() ? 'bg-poolText' : 'bg-transparent'">{{$t("My group")}}</div>
        <div @click="tableChanged(6)" class="draw-lable flex items-center">{{ $t("My Invite") }}</div>
        <div @click="tableChanged(1)" class="draw-lable flex items-center" :class="isNode() ? 'bg-poolText' : 'bg-transparent'">{{$t("Assets details")}}</div>
        <div @click="tableChanged(2)" class="draw-lable flex items-center">{{$t("language")}}</div>
        <div @click="tableChanged(3)" class="draw-lable flex items-center" style="margin-left: 11px">中文</div>
        <div @click="tableChanged(4)" class="draw-lable flex items-center" style="margin-left: 11px">English</div>
        <div @click="tableChanged(5)" class="draw-lable flex items-center">{{$t("telegram")}}</div>

      </div>
    </el-drawer>
  </div>
</template>

<script>
import {fixWallet, getContract, getWallet, isAddress, isConnected, startListenWallet} from "../utils/contract";
import FRoundAbi from "@/assets/abi/FRoundAbi.json";
import i18n from "@/i18n";
// import ERC20Abi from "@/assets/abi/ERC20Abi.json";
// import BigNumber from "bignumber.js";

export default {
  name: 'Service',
  props: {
    msg: String
  },
  mounted() {
    let self = this;
    self.$store.commit("updateWallet", getWallet())
    self.$store.commit("updateFixWallet", fixWallet())
    startListenWallet(() => {
      self.$store.commit("updateWallet", getWallet())
      self.$store.commit("updateFixWallet", fixWallet())
    });
    setInterval(() => {
      self.loadValues();
    }, 6000);
  },
  computed: {
    bgColor() {
      return "bg-"+this.$store.state.bgColor;
    },
    wallet() {
      return this.$store.state.wallet;
    },
    fixWallet() {
      return this.$store.state.fixWallet;
    },
    fixTimes() {
      return (times) => {
        if (times == 0) return ""
        const unixTimestamp = times
        const date = new Date(unixTimestamp*1000);
        // log("Unix Timestamp:",unixTimestamp)
        // log("Date Timestamp:",date.getTime())
        // log(date)
        // log("Date: "+date.getDate()+
        //     "/"+(date.getMonth()+1)+
        //     "/"+date.getFullYear()+
        //     " "+date.getHours()+
        //     ":"+date.getMinutes()+
        //     ":"+date.getSeconds());
        return date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
      }
    }
  },
  data() {
    return {
      nodeInfo: [false, false],
      drawer: false,
      index: -1,
      userInfo: {
        id: 0,
        joinTime: 0,
      },
    }
  },
  methods: {
    handleMenu() {
      this.drawer = true;
    },
    async loadValues() {
      if (!isConnected() || !isAddress(this.wallet)) {
        return
      }
      const fround = process.env.VUE_APP_FROUND;
      const froundContract = getContract(fround, FRoundAbi);
      this.userInfo = await froundContract.methods.userInfoByOwner(this.wallet).call();
      this.nodeInfo = await froundContract.methods.nodeInfoOf(this.wallet).call();
    },
    toHome() {
      this.$router.push({path: "/"});
    },
    tableChanged(index) {
      if (index == 5) {
        window.open("https://t.me/FirstDao1")
        return;
      }
      const path = this.$router.currentRoute.path;
      if (index == 0 && path != "/group") {
        this.$router.push({path: "/group"});
        this.drawer = false;
        return;
      }

      if (index == 1 && path != "/node") {
        this.$router.push({path: "/node"});
        this.drawer = false;
        return;
      }

      if (index == 3) {
        //zh-cn
        const lan = "zh";
        localStorage.setItem("lang", lan);
        i18n.locale = "zh";
        return;
      }
      if (index == 4) {
        // english
        const lan = "en";
        localStorage.setItem("lang", lan);
        i18n.locale = "en";
      }

      if (index == 6 && path != "/beInviter"){
        this.$router.push({path: "/beInviter"});
        this.drawer = false;}
    },
    isNode() {
      return this.$router.currentRoute.path === "/node";
    },
    isGNode() {
      return this.$router.currentRoute.path === "/gNode";
    },
  }
}
</script>
<style>
.el-drawer.rtl {
  background-color: transparent;
  box-shadow: none;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.drawClass {
  min-width: 216px;
  max-width: 400px;
}
.draw-lable {
  font-size: 18px;
  text-align: left;
  color: white;
  height: 40px;
  padding-left: 54px;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
