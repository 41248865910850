<template>
  <div>
    <div class="text-white mx-3.5">
      <div v-if="list.length" class="flex flex-row items-end" style="height: 67px; padding-bottom: 16px; border-bottom: solid 1px #FFFFFF1A">
        <div style="width: 152px; font-size: 15px">时间</div>
        <div style="flex: 1; font-size: 15px;">数量</div>
      </div>
      <ul>
        <li v-for="item in list" :key="item.owner">
          <div class="flex flex-row" style="height: 49px; padding-top: 12px; border-bottom: solid 1px #FFFFFF1A">
            <div style="font-size: 13px; line-height: 13px; width: 152px">{{fixTimes(item.times)}}</div>
            <div style="text-align: left; font-size: 13px; line-height: 13px; flex: 1; padding-right: 8px; word-break: break-all">{{item.owner}}</div>
          </div>
        </li>

      </ul>
    </div>
  </div>
</template>

<script>


import {fixWallet} from "@/utils/tools";
import {getWallet, isAddress, isConnected, listenWalletChanged} from "@/utils/contract";
import axios from "axios";

export default {
  name: "SuperNode",
  mounted() {
    let self = this;
    this.$store.commit("updateBgColor", "bgColor")
    listenWalletChanged("home",() => {
      self.wallet = getWallet();

      self.loadValues();
    })
  },
  computed: {
    fixWallet() {
      return (_wallet) => {
        return fixWallet(_wallet, 2, 3);
      }
    },
    fixTimes() {
      return (times) => {
        const unixTimestamp = times
        const date = new Date(unixTimestamp*1000);

        return date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate() +""+" "+date.getHours()+
            ":"+date.getMinutes()+
            ":"+date.getSeconds()
      }
    }
  },
  data() {
    return {
      wallet: "",
      list: [
        // {
        //   id: "1",
        //   times: "2022-5-22 13:22:22",
        //   owner: "0xf9596aC112A517cA033Cb9452682EC5898EA5cE4"
        // },
        // {
        //   id: "2",
        //   times: "2022-5-22 13:22:22",
        //   owner: "0xf9596aC112A517cA033Cb9452682EC5898EA5cE4"
        // },
      ]
    }
  },
  methods: {
    async loadValues() {

      try {
        if (!isConnected() || !isAddress(this.wallet)) {
          return
        }
        let instance = axios.create({
          timeout: 30000, // 请求超过5秒即超时返回错误
          headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        })
        const result = await instance.get("https://api.firstdao.cc/groupSuperNode?owner="+this.wallet);
        this.list = result.data.data;
        console.log(this.list);
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>

</style>