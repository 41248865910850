<template>
  <div>
    <div class="pb-5">
      <!--    pool info-->
      <div class="mx-4 bg-bgColor mt-10 rounded-xl" style="padding-top: 36px; padding-bottom: 45px">
        <!--      Icons-->
        <div class="mx-auto" style="width: 100px; height: 59px; position: relative;">
          <img class="absolute left-0" src="../assets/images/first.png" alt="" style="width: 59px; height: auto">
          <img class="absolute right-0" src="../assets/images/usdt.png" alt="" style="width: 59px; height: auto">
        </div>
        <div class="my-1 text-poolText text-2xl font-medium text-center">$ {{fixAmount(poolInfo.usdtAmount, 0, 18)*2}}</div>
        <div class="text-poolSecondText text-lg font-medium text-center">First/USDT</div>

        <img class="mt-6 mb-3 mx-auto" src="../assets/images/first.png" alt="" style="width: 59px; height: auto">
        <div class="my-1 text-poolText text-2xl font-medium text-center">{{fixAmount(poolInfo.firstAmount, 2, 18)}}</div>
        <div class="text-poolSecondText text-lg font-medium text-center">{{$t("Total Pool")}} First</div>

        <img class="mt-6 mb-3 mx-auto" src="../assets/images/usdt.png" alt="" style="width: 59px; height: auto">
        <div class="my-1 text-poolText text-2xl font-medium text-center">{{fixAmount(poolInfo.usdtAmount, 2, 18)}}</div>
        <div class="text-poolSecondText text-lg font-medium text-center">{{$t("Total Pool")}} Usdt</div>

        <div class="flex flex-row justify-center items-center mt-10 bg-white mx-4 rounded-lg" style="height: 41px">
          <div @click="sub" class="text-xl ml-2 inline-flex items-center justify-center" style="width: 41px; height: 24px; border: solid 1px #17191B80; color: #17191B80; border-radius: 6px">-</div>
          <div class="flex-1 text-lg font-bold text-black">{{buy}} USDT</div>
          <div @click="add" class="text-xl mr-2 inline-flex items-center justify-center" style="width: 41px; height: 24px; border: solid 1px #17191B80; color: #17191B80; border-radius: 6px">+</div>
        </div>

        <div class="mx-4 mt-3 flex flex-row place-content-between">
          <div class="text-base text-white font-medium">USDT</div>
          <div class="text-base text-white font-medium">{{fixAmount(usdtBalance, 2, 18)}}</div>
        </div>

        <div class="flex flex-row justify-center space-x-3 mt-2">
          <div @click="approve" v-if="!approved" class="py-2 rounded-full text-lg font-bold text-white px-3" style="min-width: 126px; background-color: #FFA545">{{$t("Approve")}} USDT</div>
          <div @click="deposit" class="py-2 rounded-full text-lg font-bold text-white bg-gradient-to-b from-bgFrom to-bgEnd" style="width: 126px">{{$t("Confirm")}}</div>
        </div>

      </div>

      <!--    Contract award pool-->
      <div class="mx-4 bg-bgColor mt-10 rounded-xl" style="padding-top: 36px; padding-bottom: 20px">
        <div class="mx-4 bg-award" style="min-height: 142px; border-radius: 8px">
          <div class="mx-auto pt-3 text-white font-normal text-lg">{{$t("Contract award pool")}}</div>
          <div class="my-1 text-white text-2xl font-medium text-center">$ {{fixAmount(fomoInfo.usdtAmount*2, 0, 18)}}</div>
          <el-progress :percentage="50" :show-text="false" color="#FFA545" class="mx-4"></el-progress>
          <div class="flex flex-row justify-center items-center mt-3">
            <img class="rounded-full mb-0.5" src="../assets/images/clock.png" alt="" style="width: 13px; height: 13px;">
            <div class="ml-2 text-white font-normal text-lg">{{ hours }}:{{ minutes }}:{{ seconds }}</div>
          </div>
        </div>
        <div class="pt-6 pb-3 flex flex-row mx-4 justify-between" style="border-bottom: solid 1px #FFFFFF33">
          <div class="text-poolText text-lg font-bold text-center">First/USDT</div>
          <div class="flex flex-row items-center">
            <div class="text-poolText text-lg font-bold text-center">{{ price }}</div>
            <img class="ml-1" src="../assets/images/up.png" style="width: auto; height: 14px" alt="">
          </div>
        </div>
<!--        <div class="py-3 flex flex-row mx-4 justify-between" style="border-bottom: solid 1px #FFFFFF33">-->
<!--          <div class="text-white text-lg font-medium text-center">{{$t("Still need")}}</div>-->
<!--          <div class="text-white text-lg font-bold text-center">{{fomoInfo.needOrder}} Orders</div>-->
<!--        </div>-->
        <div class="py-3 flex flex-row mx-4 justify-between">
          <div class="text-white text-lg font-medium text-center">{{$t("Total coin burn")}}</div>
          <div class="text-white text-lg font-bold text-center">{{fixAmount(fomoInfo.totalBurn, 2, 18)}} First</div>
        </div>
      </div>

      <!--    Holding assets-->
      <div class="mx-4 bg-bgColor mt-10 rounded-xl" style="padding-top: 36px;">
        <div class="py-3 flex flex-row mx-4 justify-between">
          <div class="text-poolText text-2xl font-bold text-center">{{$t("Holding assets")}}</div>
          <div @click="toLiquidity" class="text-poolSecondText text-lg font-normal text-center">{{$t("Liquidity record")}}</div>
        </div>

        <div class="py-3 flex flex-row mx-4 justify-between" style="border-bottom: solid 1px #FFFFFF33">
          <div class="text-white text-lg font-medium text-center">{{$t("Share of the liquidity pool")}}</div>
          <div class="text-white text-lg font-bold text-center">{{fixAmount(holderLPInfo.sharedOfPool, 9, 18)}}%</div>
        </div>
        <div class="py-3 flex flex-row mx-4 justify-between" style="border-bottom: solid 1px #FFFFFF33">
          <div class="flex flex-row relative">
            <img class="absolute left-0" src="../assets/images/first.png" style="width: 24px; height: auto" alt="">
            <img src="../assets/images/usdt.png" style="width: 24px; height: auto; position: absolute; left: 18px" alt=""/>
            <div class="text-white text-lg font-medium text-center" style="margin-left: 48px">LP Token</div>
          </div>
          <div class="text-white text-lg font-bold text-center">${{fixAmount(holderLPInfo.usdtAmount*2, 2, 18)}}</div>
        </div>
        <div class="py-3 flex flex-row mx-4 justify-between" style="border-bottom: solid 1px #FFFFFF33">
          <div class="flex flex-row">
            <img src="../assets/images/first.png" style="width: 24px; height: auto" alt="">
            <div class="text-white text-lg font-medium text-center" style="margin-left: 10px">{{$t("Remeedable")}} First</div>
          </div>
          <div class="text-white text-lg font-bold text-center">{{fixAmount(holderLPInfo.firstAmount, 2, 18)}}</div>
        </div>
        <div class="py-3 flex flex-row mx-4 justify-between" style="border-bottom: solid 1px #FFFFFF33">
          <div class="flex flex-row">
            <img src="../assets/images/first.png" style="width: 24px; height: auto" alt="">
            <div class="text-white text-lg font-medium text-center" style="margin-left: 10px">{{$t("Remeedable")}} USDT</div>
          </div>
          <div class="text-white text-lg font-bold text-center">{{fixAmount(holderLPInfo.usdtAmount, 2, 18)}}</div>
        </div>
        <div class="flex flex-row justify-center space-x-3 mt-2 py-6">
          <div @click="redeemLP" class="py-2 rounded-full text-lg font-bold text-white" style="width: 126px; background-color: #FFA545">{{$t("Remove")}}</div>
          <div @click="swap" class="py-2 rounded-full text-lg font-bold text-white bg-gradient-to-b from-bgFrom to-bgEnd" style="width: 126px">{{$t("Trade")}}</div>
        </div>
      </div>

      <!--    All Order-->
      <div class="mx-4 px-4 bg-bgColor mt-10 rounded-xl" style="padding-top: 36px; padding-bottom: 36px">
<!--        <div class="flex flex-col items-center justify-center bg-gradient-to-r from-cardFrom to-cardEnd rounded-xl" style="min-height: 86px">-->
<!--          <div class="text-bgColor text-lg font-medium text-center">{{ $t("Total Orders") }}</div>-->
<!--          <div class="text-white text-xl font-bold text-center">{{totalOrderInfo.totalOrders}}</div>-->
<!--        </div>-->
<!--        <div class="mt-5 flex flex-col items-center justify-center bg-gradient-to-r from-cardFrom to-cardEnd rounded-xl" style="min-height: 86px">-->
<!--          <div class="text-bgColor text-lg font-medium text-center">全网已出局订单</div>-->
<!--          <div class="text-white text-xl font-bold text-center">{{totalOrderInfo.totalOut}}</div>-->
<!--        </div>-->
        <div class="mt-5 flex flex-col items-center justify-center bg-gradient-to-r from-cardFrom to-cardEnd rounded-xl" style="min-height: 86px">
          <div class="text-bgColor text-lg font-medium text-center">{{$t("My open order")}}</div>
<!--          <div class="text-white text-xl font-bold text-center">{{totalOrderInfo.totalOut}}</div>-->
<!--          class="flex flex-row justify-center"-->
<!--          class="flex flex-row justify-center nowrap"-->
          <div style="overflow-x: auto; width: auto; max-width: 100%; list-style: none; white-space: nowrap; display: -webkit-box">
            <ul v-for="item in oreders" :key="item.id" style="display: inline-block">
              <li v-if="!item.isOut && item.times > 0" class="text-white text-xl font-bold px-2">
                {{Number(item.id)+1}}
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-5 flex flex-col items-center justify-center bg-gradient-to-r from-cardFrom to-cardEnd rounded-xl" style="min-height: 86px">
          <div class="text-bgColor text-lg font-medium text-center">{{$t("My out order")}}</div>
          <!--          <div class="text-white text-xl font-bold text-center">{{totalOrderInfo.totalOut}}</div>-->
          <div style="overflow-x: auto; width: auto; max-width: 100%; list-style: none; white-space: nowrap; display: -webkit-box">
            <div v-for="item in oreders" :key="item.id" style="display: inline-block">
              <div v-if="item.isOut && item.times > 0" class="text-white text-xl font-bold px-2">
                {{Number(item.id)+1}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--    Money Details-->
      <div class="mx-4 px-4 bg-bgColor mt-10 rounded-xl" style="padding-top: 36px; padding-bottom: 34px">
        <div class="flex flex-row items-center justify-between">
          <div class="text-poolText text-xl font-bold text-center">{{$t("Assets details")}}</div>
          <img class="rounded-full" src="../assets/images/help.png" style="width: 18px; height: auto" alt="">
        </div>

        <div class="mt-5 pl-4 pr-6 flex flex-row items-center justify-between bg-gradient-to-r from-cardFrom to-cardEnd rounded-xl" style="min-height: 57px">
          <div class="text-white text-lg font-medium text-center">{{$t("Node reward")}}：</div>
          <div class="text-white text-xl font-bold text-right">${{fixAmount(assetsTotalInfo.totalNodeInfo.usdtAmount*2, 2, 18)}} LP</div>

        </div>

        <div class="mt-5 pl-4 pr-6 flex flex-row items-center justify-between bg-gradient-to-r from-cardFrom to-cardEnd rounded-xl" style="min-height: 57px">
          <div class="text-white text-lg font-medium text-center">{{$t("Super node dividend")}}：</div>
          <div class="text-white text-xl font-bold text-right">${{fixAmount(assetsTotalInfo.totalSuperNodeInfo.usdtAmount*2, 2, 18)}} LP</div>

        </div>

        <div class="mt-5 pl-4 pr-6 flex flex-row items-center justify-between bg-gradient-to-r from-cardFrom to-cardEnd rounded-xl" style="min-height: 57px">
          <div class="text-white text-lg font-medium text-center">{{$t("FOMO reward")}}：</div>
          <div class="text-white text-xl font-bold text-right">${{fixAmount(assetsTotalInfo.totalLastInfo.usdtAmount*2, 2, 18)}} LP</div>

        </div>

        <div class="mt-5 pl-4 pr-6 flex flex-row items-center justify-between bg-gradient-to-r from-cardFrom to-cardEnd rounded-xl" style="min-height: 57px">
          <div class="text-white text-lg font-medium text-center">{{$t("Full reward")}}：</div>
          <div class="text-white text-xl font-bold text-right">${{fixAmount(assetsTotalInfo.totalFullInfo.usdtAmount*2, 2, 18)}} LP</div>
        </div>

        <div class="mt-5 pl-4 pr-6 flex flex-row items-center justify-between bg-gradient-to-r from-cardFrom to-cardEnd rounded-xl" style="min-height: 57px">
          <div class="text-white text-lg font-medium text-center">{{$t("Referral reward")}}：</div>
          <div class="text-white text-xl font-bold text-right">${{fixAmount(assetsTotalInfo.totalInviteInfo.usdtAmount*2, 2, 18)}} LP</div>
        </div>
        <div @click="toRewardDetails" class="py-2 mt-8 mx-auto rounded-full text-lg font-bold text-white bg-gradient-to-b from-bgFrom to-bgEnd" style="width: 207px">查看明细</div>
      </div>

      <!--    Your Referral Link-->
      <div class="mx-4 px-4 bg-bgColor mt-10 rounded-xl" style="padding-bottom: 34px">
        <div class="py-5" style="border-bottom: solid 1px #FFFFFF33">
          <div class="text-poolText text-xl font-bold text-left">{{$t("Your Referral Link")}}</div>
        </div>

        <div class="mt-6">
          <div class="text-white text-lg font-medium text-left break-all">{{host}}{{this.show ? "" : wallet}}</div>
          <img @click="copy" class="mx-auto mt-1" src="../assets/images/copy.png" alt="" style="width: 20px; height: auto">
        </div>

      </div>

    </div>
    <van-overlay :show="show">
      <div style="display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;">
        <div class="bg-white rounded-xl py-3 px-4" style="width: 80%; max-width: 500px; min-height: 100px">
          <p style="font-weight: bold">{{$t("Address of the Inviter")}}：</p>
          <p class="break-all">{{inviter}}</p>
          <div class="flex flex-row justify-center mt-2">
<!--            <div @click="show=false" class="px-4 py-1 rounded-lg" style="border: solid 1px grey">取消</div>-->
<!--            <div style="width: 10px;"/>-->
            <div @click="bindInvite" class="px-4 py-1 rounded-lg" style="border: solid 1px grey">{{$t("OK")}}</div>
          </div>

        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import ERC20Abi from "../assets/abi/ERC20Abi.json"
import FRoundAbi from "../assets/abi/FRoundAbi.json"
import {copy, log} from "@/utils/tools";
import {connect, getContract, getWallet, isAddress, isConnected, listenWalletChanged} from "@/utils/contract";
import BigNumber from "bignumber.js";
import {Toast} from "vant";

export default {
  name: "Home",
  computed: {
    // days() {
    //   const _days = 60*60*24;
    //   let result = Math.floor(this.timesClock/_days);
    //   if (result >= 10) {
    //     return result+"";
    //   }
    //   return "0"+result;
    // },
    hours() {
      // const _days = 60*60*24;
      const _hours = 60*60;
      let result = Math.floor(this.timesClock/_hours);
      if (result >= 10) {
        return result+"";
      }
      return "0"+result;
    },
    minutes() {
      // const _days = 60*60*24;
      const _hours = 60*60;
      let result = Math.floor(this.timesClock%_hours/60);
      if (result >= 10) {
        return result+"";
      }
      return "0"+result;
    },
    seconds() {
      // const _days = 60*60*24;
      // const _hours = 60*60;
      const _minutes = 60;
      let result = Math.floor(this.timesClock%_minutes);
      if (result >= 10) {
        return result+"";
      }
      return "0"+result;
    },
    fixAmount() {
      return (value, decimals, uint) => {
        return new BigNumber(value).div(10**uint+"").toFixed(decimals);
      }
    },

  },
  mounted() {

    this.fround = process.env.VUE_APP_FROUND;
    this.pair = process.env.VUE_APP_PAIR;
    this.usdt = process.env.VUE_APP_USDT;
    this.first = process.env.VUE_APP_FIRST;
    this.pair = "0x58BFbe202b3aE950B1c446fbB170A4d40E1628E9";

    const inviter = window.location.pathname;
    if (inviter != undefined) {
      this.inviter = inviter.replace("/", "");
      log(`this.inviter = ${this.inviter}`)
    }

    let self = this;
    listenWalletChanged("home",() => {
      const wallet = getWallet();
      self.wallet = wallet;
      self.inviterInfo();

    })
    log(`home mounted`)
    this.$store.commit("updateBgColor", "transparent")

    setInterval(() => {
      self.loadValues();
    }, 6000);

    setInterval(() => {
      if (self.fomoInfo.clock == 0) return;
      let now = Math.floor(Date.now()/1000);
      if (new BigNumber(self.fomoInfo.clock+"").lte(now+"")) {
        self.timesClock = 0;
      } else {
        self.timesClock = Math.floor(new BigNumber(self.fomoInfo.clock+"").minus(now+"").toNumber());
      }
    }, 1000);



    setTimeout(() => {
      self.loadValues();
      self.inviterInfo();
    }, 1000)
  },
  data() {
    return {
      inviter: null,
      show: false,
      host: "https://www.firstdao.cc/",
      wallet: "",
      buy: 100,
      usdtBalance: "0.00",
      fround: null,
      pair: null,
      usdt: null,
      first: null,
      approved: false,
      // uBalance: "0",
      // fBalance: "0",
      // fomoBalance: "0",
      timesClock: "0",
      price: "0",
      oreders: [
          // {
          //   id: "",
          //   isOut: false,
          // },
      ],
      poolInfo: {
        lpAmount: 0,
        usdtAmount: 0,
        firstAmount: 0
      },
      fomoInfo: {
        clock: 0,
        lpAmount: 0,
        usdtAmount: 0,
        firstAmount: 0,
        price: 0,
        needOrder: 0,
        totalBurn: 0
      },
      holderLPInfo: {
        sharedOfPool: 0,
        lpAmount: 0,
        usdtAmount: 0,
        firstAmount: 0
      },
      totalOrderInfo: {
        totalOrders: 0,
        totalActive: 0,
        totalOut: 0,
      },
      assetsTotalInfo: {
        totalNode: 0,
        totalNodeInfo: {
          usdtAmount: 0,
          firstAmount: 0
        },
        totalSuperNode: 0,
        totalSuperNodeInfo: {
          usdtAmount: 0,
          firstAmount: 0
        },
        totalLast: 0,
        totalLastInfo: {
          usdtAmount: 0,
          firstAmount: 0
        },
        totalFull: 0,
        totalFullInfo: {
          usdtAmount: 0,
          firstAmount: 0
        },
        totalInvite: 0,
        totalInviteInfo: {
          usdtAmount: 0,
          firstAmount: 0
        },
        totalWaingOut: 0,
        totalWaingOutInfo: {
          usdtAmount: 0,
          firstAmount: 0
        },
        totalBack: 0,
        totalBackInfo: {
          usdtAmount: 0,
          firstAmount: 0
        },
      },
      userInfo: {
        owner: "",
        enabledAmount: 0,
        claimedAmount: 0,
        enabledOther: 0,
        claimedOther: 0,
        freezeAmount: 0,
        freezeOther: 0,
        isOut: 0,
        totalDeposit: 0,
        inviter: 0,
        joinTime: 0,
        id: 0
      },
      zero: "0x0000000000000000000000000000000000000000",
    }
  },
  methods: {
    copy() {
      copy(this,this.host+this.wallet);
    },
    toRewardDetails() {
      this.$router.push({path: "/node"});
    },
    toLiquidity() {
      this.$router.push({path: "/liquidity"});
    },
    add() {
      this.buy += 100;
    },
    sub() {
      if (this.buy >= 200) {
        this.buy -= 100;
      }
    },
    async redeemLP() {
      const value = new BigNumber(this.userInfo.enabledOther).plus(this.userInfo.enabledAmount);
      if (!isConnected() || !isAddress(this.wallet)) {
        return
      }
      Toast.loading({
        message: 'loading...',
        loadingType: 'spinner',
        duration: 0,
      })
      log(value.toFixed(8))
      try {
        if (value.gt("0")) {
          const _value = value.toFixed(0)
          log(`value = ${_value}`)
          const froundContract = getContract(this.fround, FRoundAbi);

          await froundContract.methods.redeemLP(_value).estimateGas({gas: 5000000,from: this.wallet});
          const result = await froundContract.methods.redeemLP(_value).send({
            from: this.wallet
          });
          if (result.status) {
            this.$message.success("redeem LP success")
          } else {
            this.$message.error("redeem LP success")
          }
        } else {
          const freezeValue = new BigNumber(this.userInfo.freezeOther).plus(this.userInfo.freezeAmount);
          if (freezeValue.gt("0")) {
            log("freeze amount")
          }
        }
      } catch (e) {
        let errorList = e.message.replace("Internal JSON-RPC error.", "").split("execution reverted:");
        if (errorList.length > 1) {
          let result = errorList[1].split("\",")[0];
          this.$message.error(result)
        } else {
          this.$message.error("Redeem fail")
        }
        log(e)
      }

      Toast.clear()


    },
    async swap() {
      this.$router.push({path: "/trade"});
      /*
      if (!isConnected() || !isAddress(this.wallet)) {
        connect();
        return
      }

      Toast.loading({
        message: 'loading...',
        loadingType: 'spinner',
        duration: 0,
      })
      try {
        const froundContract = getContract(this.fround, FRoundAbi);
        const firstContract = getContract(this.first, ERC20Abi);
        const approveValue = await firstContract.methods.allowance(this.wallet, this.fround).call();
        const amount = await firstContract.methods.balanceOf(this.wallet).call();
        log(amount)
        if (new BigNumber(approveValue).lt(amount)) {
          const max = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
          let result = await firstContract.methods.approve(this.fround, max).send({
            from: this.wallet
          });
          if (result.status == false) {
            this.$message.error("approve First fail");
            Toast.clear();
            return
          }
        }
        await froundContract.methods.swap("0").estimateGas({from: this.wallet, gas: 5000000});
        let result = await froundContract.methods.swap("0").send({
          from: this.wallet
        });
        if (result.status) {
          this.$message.success("swap success");
        } else {
          this.$message.success("swap fail");
        }
        Toast.clear();
      } catch (e) {
        log(e);
        Toast.clear();
      }
      */
    },
    async bindInvite() {
      if (!isConnected() || !isAddress(this.wallet)) {
        connect();
        return
      }

      Toast.loading({
        message: 'loading...',
        loadingType: 'spinner',
        duration: 0,
      })
      try {
        const froundContract = getContract(this.fround, FRoundAbi);

        await froundContract.methods.bindInviter(this.inviter).estimateGas({gas: 5000000});
        let result = await froundContract.methods.bindInviter(this.inviter).send({
          from: this.wallet,
        });
        if (result.status) {
          this.$message.success("bind success");
          this.show = false;
        } else {
          this.$message.success("bind fail");
        }
        Toast.clear();
      } catch (e) {
        let errorList = e.message.replace("Internal JSON-RPC error.", "").split("execution reverted:");
        if (errorList.length > 1) {
          let result = errorList[1].split("\",")[0];
          this.$message.error(result)
        } else {
          this.$message.error("bind fail")
        }
        log(e);
        Toast.clear();
      }
    },
    async approve() {
      if (!isConnected() || !isAddress(this.wallet)) {
        connect();
        return
      }
      Toast.loading({
        message: 'loading...',
        loadingType: 'spinner',
        duration: 0,
      })
      const usdtContract = getContract(this.usdt, ERC20Abi);
      const max = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
      let result = await usdtContract.methods.approve(this.fround, max).send({
        from: this.wallet
      });
      if (result.status == false) {
        this.$message.error("approve USDT fail");
        Toast.clear();
        return
      } else {
        this.$message.success("approve USDT success");
      }
      Toast.clear();
    },
    async deposit() {
      if (!isConnected() || !isAddress(this.wallet)) {
        connect();
        return
      }

      Toast.loading({
        message: 'loading...',
        loadingType: 'spinner',
        duration: 0,
      })
      try {
        const froundContract = getContract(this.fround, FRoundAbi);
        const usdtContract = getContract(this.usdt, ERC20Abi);
        const approveValue = await usdtContract.methods.allowance(this.wallet, this.fround).call();
        const amount = this.buy + "000000000000000000";
        log(amount)
        if (new BigNumber(approveValue).lt(amount)) {
          const max = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
          let result = await usdtContract.methods.approve(this.fround, max).send({
            from: this.wallet
          });
          if (result.status == false) {
            this.$message.error("approve USDT fail");
            Toast.clear();
            return
          }
        }
        await froundContract.methods.buyLiquidity(amount).estimateGas({from: this.wallet, gas: 25000000});
        let result = await froundContract.methods.buyLiquidity(amount).send({
          from: this.wallet
        });
        if (result.status) {
          this.$message.success("deposit success");
        } else {
          this.$message.success("deposit fail");
        }
        Toast.clear();
      } catch (e) {
        let errorList = e.message.replace("Internal JSON-RPC error.", "").split("execution reverted:");
        if (errorList.length > 1) {
          let result = errorList[1].split("\",")[0];
          this.$message.error(result)
        } else {
          this.$message.error("Deposit fail")
        }
        log(e);
        Toast.clear();
      }
    },
    async inviterInfo() {
      if (!isConnected() || !isAddress(this.wallet)) {
        log(`this.fround = ${this.fround}`)
        return
      }
      Toast.loading({
        message: 'loading...',
        loadingType: 'spinner',
        duration: 0,
      })
      const froundContract = getContract(this.fround, FRoundAbi);
      log(`this.fround = ${this.fround}`)
      const inviter = await froundContract.methods.inviterByOwner(this.wallet).call();
      log(`inviter - b = ${inviter}`)
      if (inviter.toLowerCase() != this.zero) {
        this.show = false;
      } else {
        this.show = true;
      }
      Toast.clear();
    },
    async loadValues() {
      try {
        if (!isConnected() || !isAddress(this.wallet)) {
          return
        } else if (isAddress(this.wallet)) {
          // return;
        }
        const froundContract = getContract(this.fround, FRoundAbi);
        const usdtContract = getContract(this.usdt, ERC20Abi);

        this.poolInfo = await froundContract.methods.poolInfo().call();
        const fomoInfo = await froundContract.methods.fomoInfo().call();
        this.fomoInfo.clock = fomoInfo.clock;
        this.fomoInfo.lpAmount = fomoInfo.lpAmount;
        this.fomoInfo.totalBurn = fomoInfo.totalBurn;
        this.fomoInfo.needOrder = fomoInfo.needOrder
            this.fomoInfo.usdtAmount = new BigNumber(fomoInfo.lpAmount).times(this.poolInfo.usdtAmount).div(this.poolInfo.lpAmount).toFixed(0)
        this.fomoInfo.firstAmount = new BigNumber(fomoInfo.lpAmount).times(this.poolInfo.firstAmount).div(this.poolInfo.firstAmount).toFixed(0)

        this.price = Math.floor(new BigNumber(this.poolInfo.usdtAmount).div(this.poolInfo.firstAmount).times(10**8).toNumber())/10**8;
        const holderLPInfo = await froundContract.methods.holderLPInfoOf(this.wallet).call();
        this.holderLPInfo.lpAmount = holderLPInfo.lpAmount;
        this.holderLPInfo.sharedOfPool = new BigNumber(holderLPInfo.lpAmount).times("100").div(this.poolInfo.lpAmount).toNumber();
        this.holderLPInfo.usdtAmount = new BigNumber(holderLPInfo.lpAmount).times(this.poolInfo.usdtAmount).div(this.poolInfo.lpAmount).toFixed(0);
        this.holderLPInfo.firstAmount = new BigNumber(holderLPInfo.lpAmount).times(this.poolInfo.firstAmount).div(this.poolInfo.lpAmount).toFixed(0);


        this.totalOrderInfo = await froundContract.methods.totalOrderInfo().call();
        const assetsTotalInfo = await froundContract.methods.assetsTotalInfoByOwner(this.wallet).call();
        this.assetsTotalInfo.totalNode = assetsTotalInfo.totalNode;
        this.assetsTotalInfo.totalNodeInfo.usdtAmount = new BigNumber(assetsTotalInfo.totalNode).times(this.poolInfo.usdtAmount).div(this.poolInfo.lpAmount).toFixed(0);
        this.assetsTotalInfo.totalNodeInfo.firstAmount = new BigNumber(assetsTotalInfo.totalNode).times(this.poolInfo.firstAmount).div(this.poolInfo.lpAmount).toFixed(0);

        this.assetsTotalInfo.totalSuperNode = assetsTotalInfo.totalSuperNode;
        this.assetsTotalInfo.totalSuperNodeInfo.usdtAmount = new BigNumber(assetsTotalInfo.totalSuperNode).times(this.poolInfo.usdtAmount).div(this.poolInfo.lpAmount).toFixed(0);
        this.assetsTotalInfo.totalSuperNodeInfo.firstAmount = new BigNumber(assetsTotalInfo.totalSuperNode).times(this.poolInfo.firstAmount).div(this.poolInfo.lpAmount).toFixed(0)

        this.assetsTotalInfo.totalLast = assetsTotalInfo.totalLast;
        this.assetsTotalInfo.totalLastInfo.usdtAmount = new BigNumber(assetsTotalInfo.totalLast).times(this.poolInfo.usdtAmount).div(this.poolInfo.lpAmount).toFixed(0);
        this.assetsTotalInfo.totalLastInfo.firstAmount = new BigNumber(assetsTotalInfo.totalLast).times(this.poolInfo.firstAmount).div(this.poolInfo.lpAmount).toFixed(0)

        this.assetsTotalInfo.totalFull = assetsTotalInfo.totalFull;
        this.assetsTotalInfo.totalFullInfo.usdtAmount = new BigNumber(assetsTotalInfo.totalFull).times(this.poolInfo.usdtAmount).div(this.poolInfo.lpAmount).toFixed(0);
        this.assetsTotalInfo.totalFullInfo.firstAmount = new BigNumber(assetsTotalInfo.totalFull).times(this.poolInfo.firstAmount).div(this.poolInfo.lpAmount).toFixed(0)

        this.assetsTotalInfo.totalInvite = assetsTotalInfo.totalInvite;
        this.assetsTotalInfo.totalInviteInfo.usdtAmount = new BigNumber(assetsTotalInfo.totalInvite).times(this.poolInfo.usdtAmount).div(this.poolInfo.lpAmount).toFixed(0);
        this.assetsTotalInfo.totalInviteInfo.firstAmount = new BigNumber(assetsTotalInfo.totalInvite).times(this.poolInfo.firstAmount).div(this.poolInfo.lpAmount).toFixed(0)

        this.assetsTotalInfo.totalWaingOut = assetsTotalInfo.totalWaingOut;
        this.assetsTotalInfo.totalWaingOutInfo.usdtAmount = new BigNumber(assetsTotalInfo.totalWaingOut).times(this.poolInfo.usdtAmount).div(this.poolInfo.lpAmount).toFixed(0);
        this.assetsTotalInfo.totalWaingOutInfo.firstAmount = new BigNumber(assetsTotalInfo.totalWaingOut).times(this.poolInfo.firstAmount).div(this.poolInfo.lpAmount).toFixed(0)

        this.assetsTotalInfo.totalBack = assetsTotalInfo.totalBack;
        this.assetsTotalInfo.totalBackInfo.usdtAmount = new BigNumber(assetsTotalInfo.totalBack).times(this.poolInfo.usdtAmount).div(this.poolInfo.lpAmount).toFixed(0);
        this.assetsTotalInfo.totalBackInfo.firstAmount = new BigNumber(assetsTotalInfo.totalBack).times(this.poolInfo.firstAmount).div(this.poolInfo.lpAmount).toFixed(0)

        this.userInfo = await froundContract.methods.userInfoByOwner(this.wallet).call();

        this.usdtBalance = await usdtContract.methods.balanceOf(this.wallet).call();
        const approveValue = await usdtContract.methods.allowance(this.wallet, this.fround).call();
        if (approveValue > 0) {
          this.approved = true;
        }

        // const firstContract = getContract(this.first, ERC20Abi);
        // const pairContract = getContract(this.pair, ERC20Abi);
        // log(`this.pair = ${this.pair}`)
        // const _uBalance = await usdtContract.methods.balanceOf(this.pair).call();
        // const _fBalance = await firstContract.methods.balanceOf(this.pair).call();
        // this.uBalance = new BigNumber(_uBalance).div(10**18+"").toFixed(2);
        // this.fBalance = new BigNumber(_fBalance).div(10**18+"").toFixed(2);
        // const _fomoBalance = await pairContract.methods.balanceOf("0xA8F2cd06152C2F79a5eC901C563BAd39Dc91c8AD").call();
        // this.fomoBalance = new BigNumber(_fomoBalance).div(10**18+"").toFixed(2);

        // let _fomoTimes = await froundContract.methods.fomoTimes().call();
        //
        // this.price = new BigNumber(_uBalance).div(_fBalance).toNumber();
        //
        let now = Math.floor(Date.now()/1000);
        if (new BigNumber(this.fomoInfo.clock+"").lte(now+"")) {
          this.timesClock = 0;
        } else {
          this.timesClock = Math.floor(new BigNumber(this.fomoInfo.clock+"").minus(now+"").toNumber());
        }

        this.oreders = await froundContract.methods.getHistoryOrdersOf(this.wallet, 0, 300).call();
        // log(this.oreders)

      } catch (e) {
        log("home loadValues err")
        log(e)
      }
    }
  }
}
</script>

<style scoped>
.bg-award {
  background-image: url("../assets/images/bg-award.png");
  background-size: 100% 100%;
}
div::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>