<template>
  <div>
    <div class="flex flex-row justify-between font-medium text-white mx-2 px-2" style="
    font-size: 13px;
    border-radius: 5px;
    padding-top: 16px;
    padding-bottom: 10px;
    background-color: #FFFFFF1A">
      <div @click="tableChanged(0)" :class="index==0 ? 'text-poolText' : 'text-white'">
        {{$t("Node reward")}}
        <div v-if="index==0" class="mx-auto" style="background-color: #FFA545; width: 90%; height: 3px; border-radius: 100px"/>
      </div>
      <div @click="tableChanged(1)" :class="index==1 ? 'text-poolText' : 'text-white'">
        {{$t("Super node dividend")}}
        <div v-if="index==1" class="mx-auto" style="background-color: #FFA545; width: 90%; height: 3px; border-radius: 100px"/>
      </div>
      <div @click="tableChanged(2)" :class="index==2 ? 'text-poolText' : 'text-white'">
        {{$t("FOMO reward")}}
        <div v-if="index==2" class="mx-auto" style="background-color: #FFA545; width: 90%; height: 3px; border-radius: 100px"/>
      </div>
      <div @click="tableChanged(3)" :class="index==3 ? 'text-poolText' : 'text-white'">
        {{$t("Full reward")}}
        <div v-if="index==3" class="mx-auto" style="background-color: #FFA545; width: 90%; height: 3px; border-radius: 100px"/>
      </div>
      <div @click="tableChanged(4)" :class="index==4 ? 'text-poolText' : 'text-white'">
        {{$t("Referral reward")}}
        <div v-if="index==4" class="mx-auto" style="background-color: #FFA545; width: 90%; height: 3px; border-radius: 100px"/>
      </div>
    </div>
    <router-view></router-view>
  </div>

</template>

<script>
export default {
  name: "RewardHistory",
  data() {
    return {
      index: 0,
      routeMap: {
        0: "/node",
        1: "/sNode",
        2: "/lOrder",
        3: "/fOrder",
        4: "/invite",
      },
      routeMap2: {
        "/node": 0,
        "/sNode": 1,
        "/lOrder": 2,
        "/fOrder": 3,
        "/invite": 4,
      }
    }
  },
  mounted() {
    const index = this.routeMap2[this.$router.currentRoute.path]
    if (index == undefined || index == null) {
      this.index = 0;
    } else {
      this.index = index;
    }
  },
  methods: {
    tableChanged(index) {
      if (index == this.index) {
        return
      }
      this.index = index;
      this.$router.push({path: this.routeMap[index]});
    }
  }
}
</script>

<style scoped>

</style>