export const copy = (self, value) => {

    self.$copyText(
        value
    ).then( () => {
            self.$message({
                message: "复制成功",
                type: "success"
            });
        },
        () => {
            self.$message.error("复制失败");
        }
    );
}

export const fixWallet = (_wallet, start, end) => {
    if (_wallet && _wallet != null && _wallet.length > 10) {
        return _wallet.substr(0, start) +"***" + _wallet.substr(_wallet.length-end, _wallet.length)
    }
}

export const log = (msg) => {

    if (process.env.NODE_ENV == "development") {
        console.log(msg)
    }
}
