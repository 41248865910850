<template>
  <div>
    <div class="text-white mx-3.5">
      <div v-if="list.length" class="flex flex-row items-end" style="height: 67px; padding-bottom: 16px; border-bottom: solid 1px #FFFFFF1A">
        <div style="width: 61px; font-size: 15px">{{$t("Time")}}</div>
        <div style="width: 63px; font-size: 15px; padding-right: 11px; text-align: right">{{$t("Amount")}}</div>
        <div style="flex: 1; font-size: 15px; text-align: right; padding-right: 3px">{{$t("Sum")}}(USDT)</div>
        <div style="width: 61px; font-size: 15px; text-align: right">{{$t("User")}}</div>
      </div>
      <ul>
        <li v-for="item in list" :key="item.id">
          <div class="flex flex-row" style="height: 49px; padding-top: 12px; border-bottom: solid 1px #FFFFFF1A">
            <div style="font-size: 13px; line-height: 13px; width: 61px">{{fixTimes(item.times)}}</div>
            <div style="font-size: 13px; width: 63px; text-align: right">
              <div>{{fixFirstAmount(item)}}</div>
              <div>{{fixUsdtAmount(item, 1)}}</div>
            </div>
            <div style="font-size: 13px; flex: 1; text-align: right; padding-right: 3px">{{fixUsdtAmount(item, 2)}}</div>
            <div style="font-size: 13px; width: 61px; text-align: right">{{fixWallet(item.owner)}}</div>
          </div>
        </li>

      </ul>
    </div>
  </div>
</template>

<script>


import {fixWallet, log} from "@/utils/tools";
import BigNumber from "bignumber.js";
import {getWallet, isAddress, isConnected, listenWalletChanged} from "@/utils/contract";
// import FRoundAbi from "@/assets/abi/FRoundAbi.json";
import axios from "axios";
// import ERC20Abi from "@/assets/abi/ERC20Abi.json";

export default {
  name: "SuperNode",
  mounted() {
    this.$store.commit("updateBgColor", "bgColor");

    let self = this;
    listenWalletChanged("home",() => {
      self.wallet = getWallet();
      self.loadValues();
    })

  },
  computed: {
    fixWallet() {
      return (_wallet) => {
        return fixWallet(_wallet, 2, 3);
      }
    },
    fixAmount() {
      return (value, decimals, uint) => {
        return new BigNumber(value).div(10**uint+"").toFixed(decimals);
      }
    },
    fixFirstAmount() {
      return (item) => {
        return new BigNumber(item.lp).times(item.pFirst).div(item.pTotal).div(10**18+"").toFixed(2);
      }
    },
    fixUsdtAmount() {
      return (item, n) => {
        return new BigNumber(item.lp).times(n).times(item.pUsdt).div(item.pTotal).div(10**18+"").toFixed(2);
      }
    },
    fixTimes() {
      return (times) => {
        const unixTimestamp = times
        const date = new Date(unixTimestamp*1000);
        // log("Unix Timestamp:",unixTimestamp)
        // log("Date Timestamp:",date.getTime())
        // log(date)
        // log("Date: "+date.getDate()+
        //     "/"+(date.getMonth()+1)+
        //     "/"+date.getFullYear()+
        //     " "+date.getHours()+
        //     ":"+date.getMinutes()+
        //     ":"+date.getSeconds());
        return date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate() +"\n"+" "+date.getHours()+
            ":"+date.getMinutes()+
            ":"+date.getSeconds()
      }
    }
  },
  data() {
    return {
      list: [
        // {
        //   id: "1",
        //   orderIndex: 0,
        //   times: "0",
        //   owner: "0xf9596aC112A517cA033Cb9452682EC5898EA5cE4",
        //   amount: "0",
        //   kpi: "0",
        //   markAddr: "",
        //   isOut: false
        // }
      ]
    }
  },
  methods: {
    async loadValues() {
      if (!isConnected() || !isAddress(this.wallet)) {
        return
      }
      try {
        if (!isConnected() || !isAddress(this.wallet)) {
          return
        }
        let instance = axios.create({
          timeout: 30000, // 请求超过5秒即超时返回错误
          headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        })
        // xxxx-dao-1972762-1257428825.ap-shanghai.run.tcloudbase.com
        let result = await instance.get("https://api.firstdao.cc/rewards?owner="+this.wallet+"&type=1");
        let list = result.data.data;
        list.reverse();
        this.list = list;

      } catch (e) {
        log(e)
      }
    }
  }
}
</script>

<style scoped>

</style>