<template>
  <div>
    <div class="mx-8 mt-4 flex flex-row" style="height: 94px">
      <div @click="nodeChanged(0)" class="flex flex-row items-center justify-center" :class="'normalClass'" style="
      flex: 0.46;
      /*border-bottom-left-radius: 8px;*/
      border-top-left-radius: 8px;">
        <div>
          <img v-if="isNode||isSuperNode" src="../../assets/images/super-node.png" alt="" style="width: 35px; height: auto">
          <img v-if="!(isNode||isSuperNode)" src="../../assets/images/super-node-n.png" alt="" style="width: 35px; height: auto">
        </div>
        <div class="ml-3">
          <div class="text-white" style="font-size: 13px; font-weight: normal">{{$t("Node")}}</div>
          <div class="text-white" style="font-size: 18px; font-weight: bold">{{nodeLength}}</div>
        </div>
      </div>
      <div @click="nodeChanged(1)" class="flex flex-row items-center justify-center" :class="'normalClass'" style="
      flex: 0.54;
      /*border-bottom-right-radius: 8px;*/
      border-top-right-radius: 8px;">
        <div>
          <img v-if="!isSuperNode" src="../../assets/images/node-n.png" alt="" style="width: 35px; height: auto">
          <img v-if="isSuperNode" src="../../assets/images/super-node.png" alt="" style="width: 35px; height: auto">
        </div>
        <div class="ml-3">
          <div class="text-white" style="font-size: 13px; font-weight: normal">{{$t("Super Node")}}</div>
          <div class="text-white" style="font-size: 18px; font-weight: bold">{{ superNodeLength }}</div>
        </div>
      </div>

    </div>
    <div class="flex flex-col items-center justify-center mx-8" :class="'normalClass'" style="
      flex: 1;
      border-bottom-right-radius: 8px;
      border-bottom-right-radius: 8px;">
      <div class="text-white" style="font-size: 13px; font-weight: normal">{{$t("Teams Analysis")}}</div>
      <div class="text-white" style="font-size: 18px; font-weight: bold">{{ list.length }}</div>
    </div>

<!--    <router-view></router-view>-->
    <div>
      <div class="text-white mx-3.5">
        <div v-if="list.length" class="flex flex-row items-end" style="height: 67px; padding-bottom: 16px; border-bottom: solid 1px #FFFFFF1A">
          <div style="width: 152px; font-size: 15px">{{$t('Time')}}</div>
          <div style="flex: 1; font-size: 15px;">{{$t("User")}}</div>
        </div>
        <ul>
          <li v-for="item in list" :key="item.owner">
            <div class="flex flex-row" :class="item.totalDeposit > 0 ? 'text-poolText' : 'text-grey'" style="height: 49px; padding-top: 12px; border-bottom: solid 1px #FFFFFF1A">
              <div style="font-size: 13px; line-height: 13px; width: 152px">{{fixTimes(item.times)}}</div>
              <div style="text-align: left; font-size: 13px; line-height: 13px; flex: 1; padding-right: 8px; word-break: break-all">{{item.owner}}</div>
            </div>
          </li>

        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {getWallet, isAddress, isConnected, listenWalletChanged} from "@/utils/contract";
import {fixWallet, log} from "@/utils/tools";
import axios from "axios";

export default {
  name: "Group",
  mounted() {
    let self = this;
    this.$store.commit("updateBgColor", "bgColor")
    listenWalletChanged("home",() => {
      self.wallet = getWallet();
      self.loadValues();
    })
  },
  computed: {
    fixWallet() {
      return (_wallet) => {
        return fixWallet(_wallet, 2, 3);
      }
    },
    fixTimes() {
      return (times) => {
        const unixTimestamp = times
        const date = new Date(unixTimestamp*1000);

        return date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate() +""+" "+date.getHours()+
            ":"+date.getMinutes()+
            ":"+date.getSeconds()
      }
    }
  },
  data() {
    return {
      timer: null,
      index: 0,
      routeMap: {
        0: "/gNode",
        1: "/gsNode",
      },
      wallet: "",
      nodeLength: 0,
      superNodeLength: 0,
      isNode: false,
      isSuperNode: false,
      list: [
        // {
        //   id: "1",
        //   times: "2022-5-22 13:22:22",
        //   owner: "0xf9596aC112A517cA033Cb9452682EC5898EA5cE4"
        // },
        // {
        //   id: "2",
        //   times: "2022-5-22 13:22:22",
        //   owner: "0xf9596aC112A517cA033Cb9452682EC5898EA5cE4"
        // },
      ]
    }
  },
  methods: {
    nodeChanged(index) {
      if (this.index == index) {
        return;
      }
      // this.index = index;
      // this.$router.push({path: this.routeMap[index]});
    },
    async loadValues() {

      try {
        if (!isConnected() || !isAddress(this.wallet)) {
          return
        }
        let instance = axios.create({
          timeout: 30000, // 请求超过5秒即超时返回错误
          headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        })
        // this.wallet = "0x855C677CfEAD291e75c690D0d6146C97670B3b96";
        // xxxx-dao-1972762-1257428825.ap-shanghai.run.tcloudbase.com
        let result = await instance.get("https://api.firstdao.cc/group?owner="+this.wallet);
        log(result);
        result  = result.data.data;
        this.nodeLength = result.nodeLength;
        this.superNodeLength = result.superNodeLength;
        this.isNode = result.isNode;
        this.isSuperNode = result.isSuperNode;
        this.list = result.list;
        log(this.isNode)
        log(this.isSuperNode)

      } catch (e) {
        log(e)
      }
    }
  }
}
</script>

<style scoped>

.activeClass {
  background-color: #FFA545;
  box-shadow: 5px 0px 4px 0px #17191B;
}

.normalClass {
  background-color: #FFFFFF1A;
}

</style>