import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import Vuex from 'vuex'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import Vant from 'vant';
import 'vant/lib/index.css';

import './styles/globals.css'

import i18n from "./i18n/index";

import './assets/css/name.css'
import Group from "@/components/Group";
import Home from "@/components/Home";
import Trade from "@/components/Trade";
import RewardHistory from "@/components/RewardHistory";
import Liquidity from "@/components/Liquidity";

Vue.use(Vuex)

Vue.use(VueRouter)
Vue.use(Element)
Vue.use(Vant);

import VueClipboard from 'vue-clipboard2'
import FullOrder from "@/components/RewardHistory/components/FullOrder";
import SuperNode from "@/components/RewardHistory/components/SuperNode";
import NormalNode from "@/components/RewardHistory/components/NormalNode";
import LastOrder from "@/components/RewardHistory/components/LastOrder";
import Invite from "@/components/RewardHistory/components/Invite";

import GSuperNode from "@/components/Group/components/SuperNode";
import GNormalNode from "@/components/Group/components/NormalNode";
import BeInviter from "@/components/Group/components/BeInviter";

Vue.use(VueClipboard)

Vue.config.productionTip = false

const routes = [


  // {path: "/nftContainer",
  //   component: NftContainer,
  //   children: [
  //     // {path: "/servers", component: Servers},
  //     {path: "/myNft", component: MyNft},
  //     {path: '/stake', component: Stake},
  //   ],
  // },
  {path: "/home", component: Home},
  {path: "/beInviter", component: BeInviter},
  {
    path: '/group',
    component: Group,
    children: [
      {path: "/gNode", component: GNormalNode},
      {path: "/gsNode", component: GSuperNode},
    ],
  },
  {path: "/trade", component: Trade},
  {
    path: "/reward",
    component: RewardHistory,
    children: [
      {path: "/node", component: NormalNode},
      {path: "/sNode", component: SuperNode},
      {path: "/lOrder", component: LastOrder},
      {path: "/fOrder", component: FullOrder},
      {path: "/invite", component: Invite},
    ],
  },
  {
    path: "/liquidity",
    component: Liquidity
  },
  {path: "/", redirect: "/home"}

]


const router = new VueRouter({
  routes
})

router.beforeEach((to, from,next) => {
  // console.log("to = %s,  from = %s", to.name, from.name)
  if (to.path == from.path) {
    return
  }
  next();
})

const store = new Vuex.Store({
  state: {
    bgColor: "transparent",
    wallet: "",
    fixWallet: "",
  },
  mutations: {
    updateBgColor (state, bgColor) {
      state.bgColor = bgColor;
    },
    updateWallet(state, wallet) {
      state.wallet = wallet;
    },
    updateFixWallet(state, fixWallet) {
      state.fixWallet = fixWallet;
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
