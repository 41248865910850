<template>
  <div>
    <div class="mt-5 flex flex-col items-center justify-center bg-gradient-to-r from-cardFrom to-cardEnd rounded-xl" style="min-height: 86px">
      <div class="text-bgColor text-lg font-medium text-center">{{$t("Liquidity record")}}</div>
    </div>
    <div class="text-white mx-3.5">
      <div v-if="list.length" class="flex flex-row items-end" style="height: 67px; padding-bottom: 16px; border-bottom: solid 1px #FFFFFF1A">
        <div style="width: 61px; font-size: 15px">{{$t("Time")}}</div>
        <div style="width: 100px; font-size: 15px; padding-right: 11px; text-align: right">数量</div>
        <div style="flex: 1; font-size: 15px; text-align: right; padding-right: 3px">{{$t("Amount")}}(USDT)</div>
        <div style="width: 61px; font-size: 15px; text-align: right">{{$t("User")}}</div>
      </div>
      <ul>
        <li v-for="item in list" :key="item.id">
          <div class="flex flex-row" style="height: 49px; padding-top: 12px; border-bottom: solid 1px #FFFFFF1A">
            <div style="font-size: 13px; line-height: 13px; width: 61px">{{fixTimes(item.times)}}</div>
            <div style="font-size: 13px; text-align: right">
              <div>
                {{fixFirstAmount(item)}} FIRST
              </div>
              <div>
                {{fixUsdtAmount(item)}} USDT
              </div>
            </div>
            <div style="font-size: 13px; flex: 1; text-align: right; padding-right: 3px">{{fixAmount(item.redeem ? item.usdtAmount*2 : item.usdtAmount, 2, 18)}}</div>
            <div style="font-size: 13px; width: 61px; text-align: right">{{fixWallet(item.owner)}}</div>
          </div>
        </li>

      </ul>
    </div>
  </div>
</template>

<script>


import {fixWallet, log} from "@/utils/tools";
import BigNumber from "bignumber.js";
import axios from "axios";
import {getWallet, isAddress, isConnected, listenWalletChanged} from "@/utils/contract";
// import ERC20Abi from "@/assets/abi/ERC20Abi.json";

export default {
  name: "index",
  mounted() {
    this.$store.commit("updateBgColor", "bgColor");

    let self = this;
    listenWalletChanged("home",() => {
      self.wallet = getWallet();
      self.loadValues();
    })

  },
  computed: {
    fixWallet() {
      return (_wallet) => {
        return fixWallet(_wallet, 2, 3);
      }
    },
    fixAmount() {
      return (value, decimals, uint) => {
        return new BigNumber(value).div(10**uint+"").toFixed(decimals);
      }
    },
    fixFirstAmount() {
      return (item) => {
        if (item.redeem) {
          return new BigNumber(item.tokenAAmount).plus(item.burnAmount).div(10**18+"").toFixed(2);
        } else {
          return new BigNumber(item.lp).times(item.pFirst).div(item.pTotal).div(10**18+"").toFixed(2);
        }
      }
    },
    fixUsdtAmount() {
      return (item) => {
        if (item.redeem) {
          return new BigNumber(item.usdtAmount).div(10**18+"").toFixed(2);
        } else {
          return new BigNumber(item.usdtAmount).div("2").div(10**18+"").toFixed(2);
        }
      }
    },
    fixTimes() {
      return (times) => {
        const unixTimestamp = times
        const date = new Date(unixTimestamp*1000);
        return date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate() +"\n"+" "+date.getHours()+
            ":"+date.getMinutes()+
            ":"+date.getSeconds()
      }
    }
  },
  data() {
    return {
      list: [
        // {
        //   id: "1",
        //   orderIndex: 0,
        //   times: "0",
        //   owner: "0xf9596aC112A517cA033Cb9452682EC5898EA5cE4",
        //   amount: "0",
        //   kpi: "0",
        //   markAddr: "",
        //   isOut: false
        // }
      ]
    }
  },
  methods: {
    async loadValues() {
      if (!isConnected() || !isAddress(this.wallet)) {
        return
      }
      try {
        if (!isConnected() || !isAddress(this.wallet)) {
          return
        }
        let instance = axios.create({
          timeout: 30000, // 请求超过5秒即超时返回错误
          headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        })
        // xxxx-dao-1972762-1257428825.ap-shanghai.run.tcloudbase.com
        let result = await instance.get("https://api.firstdao.cc/liquidity?owner="+this.wallet);
        log(result);
        result  = result.data.data;
        this.list = result;

      } catch (e) {
        log(e)
      }


    }
  }
}
</script>

<style scoped>

</style>