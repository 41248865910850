<template>
  <div>
    <div class="mx-4 px-4 bg-bgColor mt-10 rounded-xl" style="padding-top: 52px; padding-bottom: 34px">
      <div class="bg-white flex flex-row items-center" style="height: 45px; border-radius: 8px">
        <input class="flex-1 h-full text-poolText text-medium px-2" type="number" v-model="amount" style="outline: none; font-size: 18px; border-radius: 8px"/>
        <div @click="maxClick" class="text-poolText mr-3" style="font-size: 18px;padding: 1px 10px 1px 10px; border: solid 1px #FFA545; border-radius: 8px">MAX</div>
      </div>
      <div class="flex flex-row justify-between mt-5 text-white">
        <div class="text-white text-lg font-medium text-center">First</div>
        <div class="text-white text-lg font-bold text-center">{{fixAmount(firstAmount, 2, 18)}}</div>
      </div>

      <div class="flex flex-row justify-between mt-5 text-white">
        <div class="text-white text-lg font-medium text-left">{{$t("Estimated profits")}}</div>
        <div class="text-white text-lg font-bold text-center">{{out}}</div>
      </div>

      <div @click="swap" class="mt-9 py-2 mx-auto rounded-full text-lg font-bold text-white bg-gradient-to-b from-bgFrom to-bgEnd" style="width: 207px">{{$t("Remove")}}</div>

    </div>
  </div>
</template>

<script>
import {connect, getContract, getWallet, isAddress, isConnected, listenWalletChanged} from "@/utils/contract";
import {Toast} from "vant";
import FRoundAbi from "@/assets/abi/FRoundAbi.json";
import RouterAbi from "@/assets/abi/RouterAbi.json";
import ERC20Abi from "@/assets/abi/ERC20Abi.json";
import BigNumber from "bignumber.js";
import {log} from "@/utils/tools";

export default {
  name: "Trade",
  mounted() {
    this.$store.commit("updateBgColor", "transparent")

    const self = this;
    listenWalletChanged("home",() => {
      self.wallet = getWallet();
    })

    setInterval(() => {
      self.loadValues();
    }, 6000)

    setInterval(() => {
      self.loadOut();
    }, 3000)

    setTimeout(() => {
      self.loadValues();
    }, 1000)
  },
  computed: {
    fixAmount() {
      return (value, decimals, uint) => {
        return new BigNumber(value).div(10**uint+"").toFixed(decimals);
      }
    },
  },
  data() {
    return {
      amount: "",
      firstAmount: 0,
      usdtAmount: 0,
      out: 0,
      wallet: "",
    }
  },
  methods: {
    maxClick() {
      const uint = new BigNumber(10).pow(18).toFixed(0);
      this.amount = new BigNumber(this.firstAmount).div(uint).toFixed(2);
    },
    async swap() {
      if (!isConnected() || !isAddress(this.wallet)) {
        connect();
        return
      }

      Toast.loading({
        message: 'loading...',
        loadingType: 'spinner',
        duration: 0,
      })
      try {
        const fround = process.env.VUE_APP_FROUND;
        const first = process.env.VUE_APP_FIRST;
        const froundContract = getContract(fround, FRoundAbi);
        const firstContract = getContract(first, ERC20Abi);
        const approveValue = await firstContract.methods.allowance(this.wallet, fround).call();
        const balance = await firstContract.methods.balanceOf(this.wallet).call();

        const uint = new BigNumber(10).pow(18).toFixed(0);
        const amount = new BigNumber(this.amount).times(uint).toFixed(0);
        if (new BigNumber(balance).lt(amount)) {
          Toast.clear();
          this.$message.warning("First 余额不足")
          return;
        }
        if (new BigNumber(approveValue).lt(balance)) {
          const max = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
          let result = await firstContract.methods.approve(fround, max).send({
            from: this.wallet
          });
          if (result.status == false) {
            this.$message.error("approve First fail");
            Toast.clear();
            return
          }
        }
        log(`this.wallet = ${this.wallet}`)
        await froundContract.methods.swap(amount).estimateGas({from: this.wallet, gas: 5000000});
        let result = await froundContract.methods.swap(amount).send({
          from: this.wallet
        });
        if (result.status) {
          this.$message.success("swap success");
          this.amount = "";
          this.out = 0;
        } else {
          this.$message.success("swap fail");
        }
        Toast.clear();
      } catch (e) {
        let errorList = e.message.replace("Internal JSON-RPC error.", "").split("execution reverted:");
        if (errorList.length > 1) {
          let result = errorList[1].split("\",")[0];
          this.$message.error(result)
        } else {
          this.$message.error("swap fail")
        }
        log(e);
        Toast.clear();
      }
    },
    async loadValues() {
      try {
        if (!isConnected() || !isAddress(this.wallet)) {
          return
        }
        const first = process.env.VUE_APP_FIRST;
        // const fround = process.env.VUE_APP_FROUND;
        const firstContract = getContract(first, ERC20Abi);
        // const froundContract = getContract(fround, FRoundAbi)
        this.firstAmount = await firstContract.methods.balanceOf(this.wallet).call();
        log(`this.firstAmount = ${this.firstAmount}`)
        // this.userInfo = await froundContract.methods.userInfoByOwner(this.wallet).call();

      } catch (e) {
        log(e)
      }
    },
    async loadOut() {
      if (this.amount == 0 || this.amount == "") {
        this.out = 0;
        return;
      }
      const router = process.env.VUE_APP_ROUTER;
      const routerContract = getContract(router, RouterAbi)
      const usdt = process.env.VUE_APP_USDT;
      const first = process.env.VUE_APP_FIRST;
      const uint = new BigNumber(10).pow(18).toFixed(0);

      const amount = new BigNumber(this.amount).times(uint).toFixed(0);
      log(`amount = ${amount}`)
      this.out = await routerContract.methods.getAmountsOut(amount, [first, usdt]).call({
        from: this.wallet
      });

      this.out = new BigNumber(this.out[this.out.length-1]).div(uint).toNumber();
    }

  }
}
</script>

<style scoped>

</style>