export default {
    "Total Pool": "总入池",
    "Contract award pool": "合约奖池",
    "Still need": "奖励还差",
    "Total coin burn": "累计销毁",
    "Holding assets": "持有资产",
    "Liquidity record": "流动性记录",
    "Share of the liquidity pool": "流动池的份额",
    "Remeedable": "可赎回",
    "Total Orders": "全网总流动订单",
    "My open order": "我的未出局订单",
    "My out order": "我的已出局订单",
    "Assets details": "资产明细",
    "Node reward": "节点奖励",
    "Super node dividend": "超级节点分红",
    "FOMO reward": "末单奖励",
    "Full reward": "满单奖励",
    "Referral reward": "推荐奖励",
    "Your Referral Link": "您的邀请链接",
    "Address of the Inviter": "邀请地址",
    "OK": "确定",
    "My group": "我的团队",
    "language": "语言",
    "telegram": "电报",
    "My Invite": "我的直推",
    "Estimated profits": "预估利润",
    "Remove": "移除",
    "Trade": "交易",
    "Time": "时间",
    "Amount": "数量",
    "Sum": "金额",
    "User": "用户",
    "Node": "节点",
    "Super Node": "超级节点",
    "Teams Analysis": "团队统计",
    "invited": "被邀请",
    "Approve": "授权",
    "Confirm": "确认"
}